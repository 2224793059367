'use client'

import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'

import { LanguagePicker } from '@/features/language-picker'
import { Navigation } from '@/features/navigation'
import { usePageProps } from '@/infrastructure/providers'
import { useParamsLocale } from '@/shared/hooks/use-params-locale.hook'
import { PropsWithClassName } from '@/shared/interfaces'

import styles from './header.styles.module.scss'

interface NavigationProps extends PropsWithClassName {}

export const Header: FC<NavigationProps> = ({ className }) => {
  const { global } = usePageProps()
  const paramsLocale = useParamsLocale()

  if (!global.header.menu.length) {
    return null
  }

  const page = global.header.menu.find((page) => page.isDefault)!
  const href = paramsLocale ? '/'.concat(paramsLocale, page.href) : page.isDefault ? '/' : page.href
  const src = new URL(global.header.logo.url, process.env.NEXT_PUBLIC_API_BASE_URL).toString()

  return (
    <section className={clsx(styles.container, className)}>
      <Link href={href}>
        <Image
          alt={global.header.logo.alternativeText}
          className={styles.logo}
          height={global.header.logo.height}
          src={src}
          width={global.header.logo.width}
        />
      </Link>
      <Navigation
        className={styles.navigation}
        menu={global.header.menu}
      />
      {/*{global.downloadButton && (
        <DownloadAppButton
          className={styles.download}
          filename={global.downloadAppButton.filename}
          href={global.downloadAppButton.href}
          target={global.downloadAppButton.target}
          title={global.downloadAppButton.title}
        />
      )}*/}
      <LanguagePicker className={styles.languagePicker} />
    </section>
  )
}
