'use client'

import { createContext, useContext } from 'react'

import { GlobalComponent } from '@/entities/global/api/interfaces/get-global-response.interface'
import { Locale } from '@/entities/locale/api/interfaces/get-locale-response.interface'
import { PageComponent } from '@/entities/page/api/interfaces/get-page-response.interface'

const pageContext = createContext<PageContextValue>({
  page: null as unknown as PageComponent,
  localizations: [] as unknown as PageComponent[],
  locales: [] as unknown as Locale[],
  global: null as unknown as GlobalComponent,
})

export const PageContextProvider = pageContext.Provider

export const usePageProps = () => useContext(pageContext)

export interface PageContextValue {
  page: PageComponent
  localizations: PageComponent[]
  locales: Locale[]
  global: GlobalComponent
}
