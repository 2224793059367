import getConfig from 'next/config'

export const env = {
  get<T = any>(key: string, defaultValue: any = null as T): T {
    const value = process.env[key] ?? getConfig()?.serverRuntimeConfig?.[key] ?? getConfig()?.publicRuntimeConfig?.[key] ?? defaultValue

    try {
      return JSON.parse(value) as T
    } catch {
      return value
    }
  },
}
