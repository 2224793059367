import Button, { ButtonProps } from 'antd/lib/button'
import { FC } from 'react'

interface PrimaryButtonProps extends Omit<ButtonProps, 'type'> {
  filename?: string
}

export const PrimaryButton: FC<PrimaryButtonProps> = ({ children, ...props }) => {
  return (
    <Button
      type="primary"
      {...props}
    >
      {children}
    </Button>
  )
}
