import clsx from 'clsx'
import { FC } from 'react'

import { LinksBanner } from '@/features/links-banner'
import { usePageProps } from '@/infrastructure/providers'
import { PropsWithClassName } from '@/shared/interfaces'

import styles from './aside.styles.module.scss'

export const Aside: FC<PropsWithClassName> = ({ className }) => {
  const { global } = usePageProps()

  return (
    <aside className={clsx(styles.container, className)}>
      <LinksBanner
        className={styles.banner}
        links={global.rightSidebar}
      />
    </aside>
  )
}
