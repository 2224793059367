'use client'

import clsx from 'clsx'
import { Fragment, ReactElement } from 'react'

import { GlobalComponent } from '@/entities/global/api/interfaces/get-global-response.interface'
import { PageComponent } from '@/entities/page/api/interfaces/get-page-response.interface'
import { AuthorArticle } from '@/entities/page/ui/author-article'
import { SeoHeader } from '@/features/seo-header'
import { PropsWithClassName } from '@/shared/interfaces'
import { SlugPageLayout } from '@/shared/ui/slug-page-layout'
import { Breadcrumbs } from '@/widgets/breadcrumbs'

import styles from './any-page-content.styles.module.css'
import dynamicContentStyles from './dynamic-content-styles.module.scss'

interface AnyPageProps extends PropsWithClassName {
  page: PageComponent
  global: GlobalComponent
}

export function AnyPage({ className, page, global }: AnyPageProps) {
  return (
    <SlugPageLayout>
      <section className={clsx(styles.container, className)}>
        <Breadcrumbs className={styles.breadcrumbs} />

        {page.blocks.map((block) => (
          <Fragment key={block.id}>{blocksFabric(block)}</Fragment>
        ))}

        <SeoHeader
          global={global}
          page={page}
        />
        {/*<PageTransitionLoader />*/}
      </section>
    </SlugPageLayout>
  )
}

function blocksFabric(block: PageComponent['blocks'][number]): ReactElement | null {
  switch (block.__component) {
    case 'content-blocks.author-article': {
      return (
        <AuthorArticle
          block={block}
          className={styles.block}
        />
      )
    }

    case 'content-blocks.html':
      if (!block.content) {
        return null
      }

      return (
        <section
          className={dynamicContentStyles.container}
          dangerouslySetInnerHTML={{ __html: block.content }}
        >
          {/*<BlocksRenderer
            blocks={{
              image: ({ image }) => (
                <Image
                  alt={image.alternativeText ?? image.name ?? ''}
                  height={image.height}
                  src={image.url}
                  width={image.width}
                />
              ),
            }}
            content={block.content}
          />*/}
        </section>
      )
    default:
      return null
  }
}
