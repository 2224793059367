import Grid from 'antd/lib/grid'
import clsx from 'clsx'
import React, { FC } from 'react'

import { DesktopLinksBanner } from '@/features/links-banner/ui/desktop-links-banner'
import { MobileLinksBanner } from '@/features/links-banner/ui/mobile-links-banner'

import { LinksBannerProps } from './links-banner.props'
import styles from './links-banner.styles.module.scss'

export const LinksBanner: FC<LinksBannerProps> = ({ className, links }) => {
  const { lg } = Grid.useBreakpoint()

  if (!links.length) {
    return null
  }

  return lg ? (
    <DesktopLinksBanner
      className={clsx(styles.container, className)}
      links={links}
    />
  ) : (
    <MobileLinksBanner
      className={clsx(styles.container, className)}
      links={links}
    />
  )
}
