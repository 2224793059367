import clsx from 'clsx'
import Link from 'next/link'
import { FC } from 'react'

import { PageComponent } from '@/entities/page/api/interfaces/get-page-response.interface'
import { useParamsLocale } from '@/shared/hooks/use-params-locale.hook'
import { PropsWithClassName } from '@/shared/interfaces'

import styles from './desktop-menu.styles.module.scss'

interface DesktopMenuProps extends PropsWithClassName {
  pages: PageComponent[]
}

export const DesktopMenu: FC<DesktopMenuProps> = ({ pages, className }) => {
  const paramsLocale = useParamsLocale()

  return (
    <ul className={clsx(styles.container, className)}>
      {pages.map((page) => {
        const href = paramsLocale ? '/'.concat(paramsLocale, page.href) : page.isDefault ? '/' : page.href

        return (
          <li
            key={page.href}
            className={styles.link}
          >
            <Link href={href}>{page.title}</Link>
          </li>
        )
      })}
    </ul>
  )
}
