'use client'

import { useParams } from 'next/navigation'
import { useMemo } from 'react'

import { usePageProps } from '@/infrastructure/providers'

export function useParamsLocale() {
  const { locales } = usePageProps()

  const { localeOrSlug } = useParams<{ localeOrSlug?: string }>()

  return useMemo(() => locales.find((locale) => locale.code === localeOrSlug)?.code, [locales, localeOrSlug])
}
