import { DoubleRightOutlined } from '@ant-design/icons'
import clsx from 'clsx'
import React, { FC } from 'react'

import { useToggle } from '@/shared/hooks/use-toggle.hook'
import { PrimaryButton, TextButton } from '@/shared/ui/button'

import { MobileLinksBannerProps } from './mobile-links-banner.props'
import styles from './mobile-links-banner.styles.module.scss'

export const MobileLinksBanner: FC<MobileLinksBannerProps> = ({ links, className }) => {
  const [isOpen, toggleIsOpen] = useToggle()

  const linksToDisplay = isOpen ? links : links.slice(0, 1)

  return (
    <section className={clsx(styles.container, { [styles.isOpen]: isOpen }, className)}>
      {links.length > 1 && (
        <TextButton
          className={styles.expandButton}
          onClick={toggleIsOpen}
        >
          <DoubleRightOutlined />
        </TextButton>
      )}
      <ul className={styles.links}>
        {linksToDisplay.map((bannerLink) => (
          <li
            key={bannerLink.link.href}
            className={styles.link}
          >
            <PrimaryButton
              className={styles.linkButton}
              filename={bannerLink.link.filename ?? undefined}
              href={bannerLink.link.href}
              target={bannerLink.link.target}
            >
              {bannerLink.link.text}
            </PrimaryButton>
          </li>
        ))}
      </ul>
    </section>
  )
}
