'use client'

import Grid from 'antd/lib/grid'
import { FC } from 'react'

import { PageComponent } from '@/entities/page/api/interfaces/get-page-response.interface'
import { PropsWithClassName } from '@/shared/interfaces'

import { DesktopMenu } from './ui/desktop-menu'
import { MobileMenu } from './ui/mobile-menu'

interface NavigationProps extends PropsWithClassName {
  menu: PageComponent[]
}

export const Navigation: FC<NavigationProps> = ({ className, menu }) => {
  const { lg } = Grid.useBreakpoint()

  return <nav className={className}>{lg ? <DesktopMenu pages={menu} /> : <MobileMenu pages={menu} />}</nav>
}
