import clsx from 'clsx'
import { FC, PropsWithChildren } from 'react'

import { PropsWithClassName } from '@/shared/interfaces'
import { Aside } from '@/widgets/aside'
import { Footer } from '@/widgets/footer'
import { Header } from '@/widgets/header'

import styles from './slug-page-layout.styles.module.scss'

interface SlugPageLayout extends PropsWithClassName, PropsWithChildren {}

export const SlugPageLayout: FC<SlugPageLayout> = ({ children, className }) => {
  return (
    <main className={clsx(styles.container, className)}>
      <header className={styles.header}>
        <Header className={styles.headerContent} />
      </header>
      <section className={styles.body}>
        <div className={styles.bodyContent}>
          {children}
          <Aside />
        </div>
        <footer className={styles.footer}>
          <Footer className={styles.footerContent} />
        </footer>
      </section>
    </main>
  )
}
