import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { FC } from 'react'

import { AuthorArticleProps } from './author-article.props'
import styles from './author-article.styles.module.scss'

export const AuthorArticle: FC<AuthorArticleProps> = ({ block, className }) => {
  const src = new URL(block.author.url, process.env.NEXT_PUBLIC_API_BASE_URL).toString()

  return (
    <section className={clsx(styles.container, className)}>
      <h2 className={styles.title}>
        <Link
          href={block.link.href}
          target={block.link.target}
        >
          {block.link.text}
        </Link>
      </h2>
      <Image
        alt={block.author.alternativeText}
        className={styles.image}
        height={block.author.height}
        src={src}
        width={block.author.width}
      />
      <section
        className={styles.contentContainer}
        dangerouslySetInnerHTML={{ __html: block.content }}
      >
        {/*<BlocksRenderer
          key={block.id}
          blocks={{
            image: ({ image }) => (
              <Image
                alt={image.alternativeText ?? image.name ?? ''}
                height={image.height}
                src={image.url}
                width={image.width}
              />
            ),
          }}
          content={block.content}
        />*/}
      </section>
    </section>
  )
}
