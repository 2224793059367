import Head from 'next/head'
import Script from 'next/script'
import React, { FC, Fragment, useMemo } from 'react'

import { GlobalComponent } from '@/entities/global/api/interfaces/get-global-response.interface'
import { PageComponent } from '@/entities/page/api/interfaces/get-page-response.interface'
import { LinkHtmlTag, MetaHtmlTag, ScriptHtmlTag, TitleHtmlTag } from '@/shared/http/interfaces/html-tag.interface'
import { OneOf } from '@/shared/interfaces'

interface SeoHeaderProps {
  page: PageComponent
  global: GlobalComponent
}

export const SeoHeader: FC<SeoHeaderProps> = ({ page, global }) => {
  const headMap = useMemo(() => {
    const pageHead = page.head
    const globalHead = global.head

    const keys: Record<GlobalComponent['head'][number]['__component'], Set<string>> = {
      'html-tags.title': new Set(),
      'html-tags.meta': new Set(),
      'html-tags.script': new Set(),
      'html-tags.link': new Set(),
    }

    return pageHead.concat(globalHead).filter((item) => {
      const currentKeySet = keys[item.__component]
      switch (item.__component) {
        case 'html-tags.meta': {
          if (!currentKeySet.has(item.name)) {
            currentKeySet.add(item.name)

            return true
          }

          return false
        }
        case 'html-tags.link': {
          if (!currentKeySet.has(item.href)) {
            currentKeySet.add(item.href)

            return true
          }

          return false
        }
        case 'html-tags.script': {
          if (!currentKeySet.has(item.src?.url ?? item.code!)) {
            currentKeySet.add(item.src?.url ?? item.code!)

            return true
          }

          return false
        }
      }
    })
  }, [global.head, page.head])

  const scripts = headMap.filter((item): item is ScriptHtmlTag => item.__component === 'html-tags.script')
  const restMeta = headMap.filter((item): item is OneOf<[MetaHtmlTag, LinkHtmlTag]> =>
    ['html-tags.meta', 'html-tags.link'].includes(item.__component),
  )

  const title = useMemo(() => {
    const pageHeadTitle = page.head.find((headBlock): headBlock is TitleHtmlTag => headBlock.__component === 'html-tags.title')
    if (!pageHeadTitle) {
      return null
    }

    return <title>{pageHeadTitle.content}</title>
  }, [page.head])

  return (
    <>
      <Head>
        {title}
        {restMeta.map(getComponentByType)}
      </Head>
      {scripts.map(getComponentByType)}
    </>
  )
}

function getComponentByType(component: GlobalComponent['head'][number], index: number) {
  switch (component.__component) {
    case 'html-tags.meta':
      return (
        <meta
          key={index}
          content={component.content}
          name={component.name}
        />
      )
    case 'html-tags.link':
      return (
        <link
          key={index}
          crossOrigin={component.crossOrigin ?? undefined}
          href={component.href}
          hrefLang={component.hrefLang ?? undefined}
          media={component.media ?? undefined}
          referrerPolicy={component.referrerPolicy ?? undefined}
          rel={component.rel}
        />
      )
    case 'html-tags.script':
      return (
        <Script
          key={index}
          dangerouslySetInnerHTML={{ __html: component.code ?? '' }}
          id={component.id.toString()}
          src={component.src?.url}
          strategy={component.strategy}
          type={component.type}
        />
      )
    default:
      return <Fragment key={index} />
  }
}
