'use client'

import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/next'
import Script from 'next/script'
import React, { FC, PropsWithChildren } from 'react'

import { env } from '@/shared/env'

import { ThemeProvider } from './theme'
import { PageContextProvider, PageContextValue } from './use-page-props.hook'

const Env = process.env.NODE_ENV! // NOTE: variable is null with "env.get" for some reason
const AppId = env.get('ONE_SIGNAL_APP_ID')
const SafariWebId = env.get('ONE_SIGNAL_SAFARI_WEB_ID')

interface ProvidersProps extends PropsWithChildren {
  value: PageContextValue
}

export const Providers: FC<ProvidersProps> = ({ children, value }) => {
  return (
    <ThemeProvider>
      <PageContextProvider value={value}>{children}</PageContextProvider>
      {Env === 'production' && (
        <>
          <Analytics />
          <SpeedInsights />
          <Script
            onReady={() => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              window.OneSignalDeferred = window.OneSignalDeferred || []
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              window.OneSignalDeferred.push((OneSignal) => {
                OneSignal.init({
                  appId: AppId,
                  safari_web_id: SafariWebId,
                  notifyButton: {
                    enable: true,
                  },
                  allowLocalhostAsSecureOrigin: true,
                })
              })
            }}
            src="https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js"
            defer
          />
        </>
      )}
    </ThemeProvider>
  )
}
