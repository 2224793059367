import clsx from 'clsx'
import React, { FC } from 'react'

import { PrimaryButton } from '@/shared/ui/button'

import { DesktopLinksBannerProps } from './desktop-links-banner.props'
import styles from './desktop-links-banner.styles.module.scss'

export const DesktopLinksBanner: FC<DesktopLinksBannerProps> = ({ links, className }) => {
  return (
    <section className={clsx(styles.container, className)}>
      <ul className={styles.links}>
        {links.map((bannerLink) => (
          <li
            key={bannerLink.link.href}
            className={styles.link}
          >
            <PrimaryButton
              className={styles.linkButton}
              filename={bannerLink.link.filename ?? undefined}
              href={bannerLink.link.href}
              target={bannerLink.link.target}
            >
              {bannerLink.link.text}
            </PrimaryButton>
          </li>
        ))}
      </ul>
    </section>
  )
}
