import clsx from 'clsx'
import { FC } from 'react'

import { PropsWithClassName } from '@/shared/interfaces'

import styles from './footer.styles.module.scss'

interface FooterProps extends PropsWithClassName {}

export const Footer: FC<FooterProps> = ({ className }) => {
  return <section className={clsx(styles.container, className)}>© 2022-{new Date().getUTCFullYear()} LSD Inc.</section>
}
