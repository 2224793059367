'use client'

import { useCallback, useState } from 'react'

export function useToggle(defaultValue = false) {
  const [state, setState] = useState(defaultValue)
  const toggleState = useCallback(() => setState((prev) => !prev), [setState])

  return [state, toggleState, setState] as const
}
