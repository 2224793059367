'use client'

import Select, { BaseOptionType } from 'antd/lib/select'
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { FlattenOptionData } from 'rc-select/lib/interface'
import { FC, memo, ReactElement, ReactNode, useEffect, useMemo } from 'react'

import { Locale } from '@/entities/locale/api/interfaces/get-locale-response.interface'
import { PageComponent } from '@/entities/page/api/interfaces/get-page-response.interface'
import { usePageProps } from '@/infrastructure/providers'
import { useLocale } from '@/shared/hooks/use-locale.hook'
import { PropsWithClassName } from '@/shared/interfaces'

import styles from './language-picker.styles.module.scss'

interface LanguagePickerProps extends PropsWithClassName {}

export const LanguagePicker: FC<LanguagePickerProps> = ({ className }) => {
  const { locales, page, localizations } = usePageProps()
  const currentLocale = useLocale()

  const options = useMemo(() => locales.filter((locale) => locale.code !== currentLocale).map(localeToSelectOption), [locales, currentLocale])

  // NOTE: any coz of eslint error for defaultValue of Select
  const defaultValue = useMemo<any>(
    () => localeToSelectOption(locales.find((locale) => locale.code === currentLocale)!),
    [currentLocale, locales],
  )

  useEffect(() => {
    document.documentElement.setAttribute('lang', currentLocale)
  }, [currentLocale])

  return (
    <Select<any, SelectOption>
      aria-label="country-flag"
      className={clsx(styles.select, className)}
      defaultValue={defaultValue}
      fieldNames={{ value: 'code' }}
      optionRender={optionRender(page, localizations)}
      options={options}
      popupClassName={styles.popup}
      rootClassName={styles.container}
      suffixIcon={null}
      variant="borderless"
    />
  )
}

function optionRender(currentPage: PageComponent, localizations: PageComponent[]) {
  return function (option: FlattenOptionData<BaseOptionType>): ReactNode {
    const locale = option.value!.toString()
    const src = new URL(`${locale === 'en' ? 'gb' : locale}.svg`, process.env.NEXT_PUBLIC_API_FLAGS_URL).toString()
    const localePage = localizations.find((localizedPage) => localizedPage.locale === locale) ?? currentPage

    const slugHref = localePage.href
    const isDefaultLocale = Reflect.get(option.data, 'isDefault')

    const href = isDefaultLocale ? (localePage.isDefault ? '/' : slugHref) : '/'.concat(locale, slugHref)

    return (
      <Link href={href}>
        <Image
          alt={locale}
          height={100}
          src={src}
          width={210}
        />
      </Link>
    )
  }
}

const LocaleLabel: FC<{ locale: Locale }> = memo(({ locale }) => {
  const src = new URL(`${locale.code === 'en' ? 'gb' : locale.code}.svg`, process.env.NEXT_PUBLIC_API_FLAGS_URL).toString()

  return (
    <Image
      alt={locale.name}
      height={100}
      src={src}
      width={210}
    />
  )
})

function localeToSelectOption(locale: Locale): SelectOption {
  return {
    ...locale,
    label: <LocaleLabel locale={locale} />,
  }
}

interface SelectOption extends BaseOptionType, Locale {
  label: ReactElement
}
